<template>
    <New/>
</template>

<script>
import New from '@components/news/News.vue';

export default {
    name: 'NewsDetails',
    components: {
        New,
    },
};
</script>
