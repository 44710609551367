<template>
    <div class="card">
        <div class="card-image">
            <figure class="image is-4by3">
                <div v-if="news.image">
                    <img class="image-news-details"
                         :src="route('files.show', { file: news.image.id })"
                         :alt="news.title">
                </div>
                <!--                <img :src="news.image.path" alt="Imagen de la noticia">-->
            </figure>
        </div>
        <div class="card-content">
            <time class="date"> {{ formatDate(news.created_at) }} </time>
            <div class="media">
                <div class="media-content">
                    <p class="title is-4 text-news">
{{ news.title }}
</p>
                </div>
            </div>
            <div class="content" v-html="news.description"/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'New',
    inject: ['http', 'route'],
    data() {
        return {
            news: {},
        };
    },
    mounted() {
        this.fetch();
    },
    methods: {
        formatDate(date) {
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            return new Date(date).toLocaleDateString('es-ES', options);
        },
        fetch() {
            this.http
                .get(this.route('news.show', this.$route.params.news))
                .then(({ data }) => {
                    this.news = data.news;
                })
                .catch(this.errorHandler);
        },
    },
};
</script>

<style lang="scss">
@import "@arsass/enso.scss";
.image-news-details{
    object-fit: cover;
}
.text-news{
    overflow: hidden;
    text-align: center;
}
.date{
    font-size: 12px;
    display: flex;
    justify-content: center;
}

</style>
